nav {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 0.5rem;
}

.active {
    background: rgba(63, 63, 63, 0.9) !important;
}
.navItem {
    background: rgba( 113, 113, 113, 0.7 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 1rem;
    color: #FFF;
    font-size: 18px;
}

.navItem:hover {
    transition: 1s ease-out;
    cursor: pointer;
    background: rgba(63, 63, 63, 0.9);

}
#time {
    color: rgb(48, 48, 48);
}


.primaryButton {
    padding: 1.5em 5em;
    background: #efefef;
    border: none;
    border-radius: .5rem;
    color: #444;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .2rem;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;
    box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
                -6px -6px 10px rgba(255, 255, 255, .5),
                6px 6px 8px rgba(255, 255, 255, .075),
                6px 6px 10px rgba(0, 0, 0, .15);
}
.primaryButton:active {
    background:#dcdcdc;

}

.buttonContainer {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.secondaryButton {
    padding: 0.9em 1.5em;
    border: none;
    border-radius: .5rem;
    background: #717171;

    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .05rem;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;



}

.secondaryButton:active {
    background:#dcdcdc;
}

.secondaryButton > img {
    padding: 0;
    width: 40px;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    

    background-color: #ECF0F3;
    border-radius: 0.5rem;
    height: 350px;
    width: 450px;
    margin-top: 1.5rem;

}